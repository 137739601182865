@import "../../mixins";

.carousel {
  position: relative;
  // max-height: 90vh;
  // max-width: 100vw;
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 1fr; 
  grid-template-rows: 1fr 3fr 3fr 3fr; 
  gap: 5px 0px;
  padding-top: 100px;


  @include breakpoint(tablet) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 10px;
    grid-template-areas: 
      ". . . btn_close"
      ". main_pic main_pic ."
      "arrow_left main_pic main_pic arrow_right"
      ". main_pic main_pic .";
      
  }
}

.image {
  animation: imageAnim 1s ease;
}

@keyframes imageAnim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hidden {
  display: none;
}
.active-pic {
  grid-area: 2 / 2 / 5 / 4;
}

.main-pic {
  max-width: 100%;
  max-height: 80vh;
  border-radius: 5px;
  object-fit: cover;
  justify-self: center;

      

  @include breakpoint(tablet) {
    grid-area: main_pic; 
    // max-width: 100%;
    // max-height: 80vh;
    // border-radius: 5px;
    /* object-fit: cover; */
    /* justify-content: center; */
    
  }
}
// .smaller-pic {
//   width: 100%;
//   height: auto;
//   border-radius: 5px;
//   object-fit: cover;
// }

// .right-pic {
//   cursor: pointer;
//   grid-area: 2 / 3 / 4 / 3;
// }
// .left-pic {
//   cursor: pointer;
//   grid-area: 2 / 2 / 3 / 3;
// }

.arrow-right {
  width: 8vw;
  position: relative;
  // top: 50%;
  
  z-index: 1;
  cursor: pointer;
  user-select: none;
  grid-row-start: 3;
  grid-column-start: 4;
  // transform: translateY(-50%);
  align-self: center;
  justify-self: center;
  @include breakpoint(tablet) {
    grid-area: arrow_right;
    justify-self: start;
  }
  @include breakpoint(laptop) {
    width: 5vw;
    max-width: 70px;
  }
  &:hover {
    // filter: drop-shadow(0px 0px 3px rgb(220, 20, 203));
  }
}

.arrow-left {
  width: 8vw;
  height: auto;
  position: relative;
  // top: 50%;
  //   right: 25px;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  grid-row-start: 3;
  grid-column-start: 1;
  // transform: translateY(-50%);
  align-self: center;
  justify-self: center;
  @include breakpoint(tablet) {
    grid-area: arrow_left;
    justify-self: end;
  }
  @include breakpoint(laptop) {
    width: 5vw;
    max-width: 70px;
  }
  &:hover {
    // filter: drop-shadow(0px 0px 5px rgb(220, 20, 203));
  }
}

.btn-close {
  width: 10vw;
  z-index: 1;
  grid-row-start: 1;
  grid-column-start: 4;
  position: relative;
  align-self: center;
  justify-self: center;
  // left: 50%;
  // transform: translateX(calc(-50% - 5px));
  // position: absolute;
  // right: 5px;
  cursor: pointer;
  @include breakpoint(tablet) {
    grid-area: btn_close; 
    justify-self: start;
  }
  @include breakpoint(laptop) {
    width: 5vw;
    max-width: 50px;
  }
}

// .prev-text {
//   position: relative;
//   align-self: end;
//   justify-self: center;
//   background-color: rgb(0, 0, 0);
//   color: white;
//   grid-area: 2 / 2 / 4 / 3;
//   padding: 1px 10px;
//   width: calc(40vw - 20px);
//   text-align: center;
//   border-radius: 5px;
//   cursor: pointer;
//   z-index: 5;
// }
// .next-text {
//   position: relative;
//   align-self: end;
//   justify-self: center;
//   background-color: rgb(0, 0, 0);
//   color: white;
//   grid-area: 2 / 3 / 4 / 3;
//   padding: 1px 10px;
//   width: calc(40vw - 20px);
//   text-align: center;
//   border-radius: 5px;
//   cursor: pointer;
//   z-index: 5;
// }
