@import "../../variables";
@import "../../mixins";

#place {
  padding-top: $paddingTop;
}

// .place-title {
// }

.place-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  margin-top: 50px;
}

#google-map {
  position: relative;
  width: 80vw;
  height: 50vh;
  @include breakpoint(phone-landscape){
    height: 70vh;
  }
  @include breakpoint(largescreen){
    width: 50vw;
    
  }
}

.place-img {
  width: 100%;
  max-width: 700px;
  // border-radius: 5px;
  // margin: 10px;
}

.container-img-place {
  width: 80%;
  @include breakpoint(desktop){
display: grid;
grid-template-columns: 1fr 1fr;
column-gap: 20px;
width: 90%;

  }
}

.place-img1 {
  justify-self: start;
}

.place-img2 {
justify-self: end;
}