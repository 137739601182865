@import "../../variables";
@import "../../mixins";

#contact {
  padding-top: $paddingTop;
  padding-bottom: $paddingTop;
}

.contact {
  
  overflow: auto;
  // text-transform: uppercase;
  // width: 99%;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: center;
  @include breakpoint(desktop){
    display: flex;
    flex-direction: row;
    column-gap: 50px;
    justify-content: center;
    
  }

}

.contact > div {
  margin-bottom: 50px;
}
.contact > div h3 {
  padding-bottom: 10px;
}

// .contact > div a {
//   background-color: $mainColor;
//   color: white;
//   text-decoration: none;
//   padding: 15px 20px;
//   border-radius: 5px;
//   box-shadow: 0 3px 5px rgba(0, 0, 0, 0.288);
//   font-size: large;
//   &:active {
//     box-shadow: none;
//   }
// }
