@import "../../variables";

.schedule {
  padding-top: $paddingTop;
}

.table-schedule {
  margin: 0 auto;
  text-align: initial;
}

.table-schedule td {
  padding: 10px;
}
