@import "../../variables";
@import "../../mixins";

.about {
  padding-top: $paddingTop;

  

}

.about p{
  padding: 0 10px;
}
