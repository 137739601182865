@import "../../variables";
@import "../../mixins";


.container-certificate {
  display: flex;
    justify-content: center;
    column-gap: 50px;
    flex-direction: column;
    @include breakpoint(desktop){
      flex-direction: row;
    }
}

.certificate-card {
  width: 90%;
  max-width: 300px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.288);
  margin: 30px auto;
  padding: 5px;
  border-radius: 5px;
  position: relative;
}
.certificate-card h4 {
  color: $mainColor;
}

.certificate-card p {
  font-size: 0.8em;
}

.medal {
  position: absolute;
  fill: $mainColor;
  width: 50px;
  height: auto;
  text-align: initial;
  bottom: -15px;
  right: 0;
  //   transform: translate(-50%, -50%);
}
