@import "../../variables";
@import "../../mixins";

#gallery {
  padding-top: $paddingTop;
}

.gallery {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  gap: 20px;
  padding-bottom: 50px;
}

.btn-gallery {
  // min-width: 215px;
  width: 100%;
  background-color: white;
  color: $mainColor;
  border: 3px solid $mainColor;

  &:hover {

    transition: 0.2s;
    background-color: $mainColor;
  color: white;
  }
}

.smaller-pic {
  width: 15%;
  height: auto;
  border-radius: 5px;
  // object-fit: cover;
  margin: 5px;
  cursor: pointer;
}

.img-border{
  border: 3px solid black;
}

.img-under-img {
  margin: 0 auto;
  @include breakpoint(tablet){
    max-width: 580px;
  }
  @include breakpoint(laptop) {
    max-width: initial;
  }
  
}
