@import "../../../mixins";

.intro-img-grid {
  display: grid;
  justify-items: center;
  row-gap: 15px;
  column-gap: 15px;

  @include breakpoint(desktop){
    grid-template-rows: 50vh 50vh;
    grid-template-columns: 50% 50%;
  }

}

.intro-img {
  width: 80%;
  object-fit: cover;
  &:nth-child(2n) {
    width: 70%;
  }
  @include breakpoint(desktop){
    &:nth-child(2), &:nth-child(3){
      width: 70%;
    }

  }
}

#introImg1 {
  

  @include breakpoint(desktop){
    width: calc(100% - 15px);
    justify-self: end;
  align-self: end;
}

}

#introImg2 {
  
  @include breakpoint(desktop){
    justify-self: start;
  align-self: end;
    width: calc(90% - 15px);
  }
    
}

#introImg3 {
  
  @include breakpoint(desktop){
    justify-self: end;
  align-self: start;
    width: calc(90% - 15px);
}
}

#introImg4 {
  
  @include breakpoint(desktop){
    justify-self: start;
  align-self: start;
    width: calc(100% - 15px);
}
}