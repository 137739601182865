@import "../../../mixins";

//hamburger menu
.burger {
  position: fixed;
  top: 0px;
  right: 0px;
  padding: 20px 20px 20px 20px;
  display: block;
  cursor: pointer;
  z-index: 100;
  width: 50px;
  background: WHITE;
  border-radius: 5px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.329);
  //   height: 5 0px;
  //   @media (min-width: $breakpoint--medium-device) {
  //     width: 150px;
  //     height: 150px;
  //   }

  @include breakpoint(desktop){
    display:none;
  }
}

.burger__line {
  height: 4px;
  margin: 4px;
  background-color: black;
  transition: 0.3s;
  float: right;
  width: 40px;
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-9px, 8px);
  background-color: black;
  //   @media (min-width: $breakpoint--medium-device) {
  //     transform: rotate(-45deg) translate(-12px, 12px);
  //   }
}

.toggle .line2 {
  opacity: 0;
}

.toggle .line3 {
  transform: rotate(45deg) translate(-9px, -8px);
  background-color: black;
  //   @media (min-width: $breakpoint--medium-device) {
  //     transform: rotate(45deg) translate(-12px, -12px);
  //   }
}
