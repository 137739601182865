@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300&display=swap);
html{scroll-behavior:smooth}body{margin:0;font-family:"Titillium Web", sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;font-size:1.2em}code{font-family:source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace}

.btn{text-decoration:none;background-color:#cc4cd5;color:#fff;padding:15px 20px;border-radius:5px;border:none;box-shadow:0 0px 5px rgba(0,0,0,0.288);font-size:1em;text-transform:uppercase;cursor:pointer;transition:0.2s ease-in-out}.btn:hover{box-shadow:none;-webkit-transform:scale(1.1);transform:scale(1.1)}.hidden{display:none}.wrapper{margin:0 auto;text-align:center}@media screen and (min-width: 992px){.wrapper{width:800px}}@media screen and (min-width: 1200px){.wrapper{width:1100px}}


.nav{position:fixed;display:flex;flex-direction:column;justify-content:center;align-items:center;background-color:white;height:100vh;width:100%;top:0px;right:0;-webkit-transform:translatex(100%);transform:translatex(100%);transition:0.4s ease-in-out;z-index:20}@media screen and (min-width: 992px){.nav{display:flex;flex-direction:row;justify-content:center;align-items:center;height:80px;top:0px;right:0;-webkit-transform:none;transform:none;-webkit-transform:initial;transform:initial;z-index:20;padding:0;background-color:#ebebeb}}.nav__item{margin-top:2.5em;margin-bottom:2.5em;opacity:1;text-transform:uppercase;list-style:none;text-align:center}@media screen and (min-width: 992px){.nav__item{display:inline;padding:0px 10px;margin-top:0;margin-bottom:0}}.nav__item a{font-size:calc(1em + 2vw);text-decoration:none;color:black}@media screen and (min-width: 992px){.nav__item a{display:inline;font-size:1em;transition:0.1s ease-in-out}.nav__item a:hover{border-bottom:3px solid #cc4cd5}}.nav-active{-webkit-transform:translatex(0%);transform:translatex(0%)}.nav-list{padding:0px}@media screen and (min-width: 992px){.nav-list{display:flex;align-items:center;justify-content:flex-end;grid-column-gap:10px;-webkit-column-gap:10px;column-gap:10px}}@media screen and (min-width: 992px){.btn-contact{text-decoration:none;background-color:#cc4cd5;color:#fff;padding:10px 15px;border-radius:5px;border:none;box-shadow:0 0px 3px rgba(0,0,0,0.288);font-size:1em;cursor:pointer}.btn-contact:active{box-shadow:none}}@media screen and (min-width: 992px){.btn-contact a{color:white}.btn-contact a:hover{border-bottom:none}}

.burger{position:fixed;top:0px;right:0px;padding:20px 20px 20px 20px;display:block;cursor:pointer;z-index:100;width:50px;background:WHITE;border-radius:5px;box-shadow:0 3px 5px rgba(0,0,0,0.329)}@media screen and (min-width: 992px){.burger{display:none}}.burger__line{height:4px;margin:4px;background-color:black;transition:0.3s;float:right;width:40px}.toggle .line1{-webkit-transform:rotate(-45deg) translate(-9px, 8px);transform:rotate(-45deg) translate(-9px, 8px);background-color:black}.toggle .line2{opacity:0}.toggle .line3{-webkit-transform:rotate(45deg) translate(-9px, -8px);transform:rotate(45deg) translate(-9px, -8px);background-color:black}

.intro{position:relative;display:grid;justify-items:center;grid-row-gap:30px;row-gap:30px;padding:70px 20px 20px 20px;margin-top:50px;margin-bottom:50px}@media screen and (min-width: 992px){.intro{margin-top:100px;position:relative;display:flex;grid-column-gap:50px;-webkit-column-gap:50px;column-gap:50px;padding:30px 0 0 0;justify-content:center}}@media screen and (min-width: 1200px){.intro{margin-top:200px;justify-content:space-between}}.intro-logo{width:100%;max-width:400px;grid-row:1 / 2;align-self:center;object-fit:cover}.intro-text{grid-row:2 / 3;align-self:center;font-size:1.3em}.intro-button{grid-row:3 / 4;align-self:center;background-color:#cc4cd5;color:white;padding:15px 20px;border-radius:5px;border:none;box-shadow:0 3px 5px rgba(0,0,0,0.288);font-size:1em;cursor:pointer}.intro-button:active{box-shadow:none}.intro-button-link{z-index:5}.intro-bg{width:100%;max-width:400px;margin-top:50px;margin-left:10px}@media screen and (min-width: 1200px){.intro-bg{max-width:500px}}.intro-img{width:100%}.container-intro{display:flex;flex-direction:column;grid-row-gap:20px;row-gap:20px;align-items:center}@media screen and (min-width: 992px){.container-intro{align-items:flex-start;max-width:400px;text-align:left}}

.intro-img-grid{display:grid;justify-items:center;grid-row-gap:15px;row-gap:15px;grid-column-gap:15px;-webkit-column-gap:15px;column-gap:15px}@media screen and (min-width: 992px){.intro-img-grid{grid-template-rows:50vh 50vh;grid-template-columns:50% 50%}}.intro-img{width:80%;object-fit:cover}.intro-img:nth-child(2n){width:70%}@media screen and (min-width: 992px){.intro-img:nth-child(2),.intro-img:nth-child(3){width:70%}}@media screen and (min-width: 992px){#introImg1{width:calc(100% - 15px);justify-self:end;align-self:end}}@media screen and (min-width: 992px){#introImg2{justify-self:start;align-self:end;width:calc(90% - 15px)}}@media screen and (min-width: 992px){#introImg3{justify-self:end;align-self:start;width:calc(90% - 15px)}}@media screen and (min-width: 992px){#introImg4{justify-self:start;align-self:start;width:calc(100% - 15px)}}

#place{padding-top:70px}.place-container{position:relative;display:flex;flex-direction:column;flex-wrap:wrap;align-items:center;grid-gap:15px;gap:15px;margin-top:50px}#google-map{position:relative;width:80vw;height:50vh}@media screen and (min-width: 300px) and (orientation: landscape){#google-map{height:70vh}}@media screen and (min-width: 1200px){#google-map{width:50vw}}.place-img{width:100%;max-width:700px}.container-img-place{width:80%}@media screen and (min-width: 992px){.container-img-place{display:grid;grid-template-columns:1fr 1fr;grid-column-gap:20px;-webkit-column-gap:20px;column-gap:20px;width:90%}}.place-img1{justify-self:start}.place-img2{justify-self:end}

#gallery{padding-top:70px}.gallery{display:flex;flex-direction:column;flex-wrap:wrap;align-content:center;grid-gap:20px;gap:20px;padding-bottom:50px}.btn-gallery{width:100%;background-color:white;color:#cc4cd5;border:3px solid #cc4cd5}.btn-gallery:hover{transition:0.2s;background-color:#cc4cd5;color:white}.smaller-pic{width:15%;height:auto;border-radius:5px;margin:5px;cursor:pointer}.img-border{border:3px solid black}.img-under-img{margin:0 auto}@media screen and (min-width: 576px){.img-under-img{max-width:580px}}@media screen and (min-width: 768px){.img-under-img{max-width:none;max-width:initial}}

.carousel{position:relative;display:grid;grid-template-columns:1fr 3fr 3fr 1fr;grid-template-rows:1fr 3fr 3fr 3fr;grid-gap:5px 0px;gap:5px 0px;padding-top:100px}@media screen and (min-width: 576px){.carousel{grid-template-columns:1fr 1fr 1fr 1fr;grid-template-rows:auto;grid-column-gap:10px;-webkit-column-gap:10px;column-gap:10px;grid-template-areas:". . . btn_close" ". main_pic main_pic ." "arrow_left main_pic main_pic arrow_right" ". main_pic main_pic ."}}.image{-webkit-animation:imageAnim 1s ease;animation:imageAnim 1s ease}@-webkit-keyframes imageAnim{0%{opacity:0}100%{opacity:1}}@keyframes imageAnim{0%{opacity:0}100%{opacity:1}}.hidden{display:none}.active-pic{grid-area:2 / 2 / 5 / 4}.main-pic{max-width:100%;max-height:80vh;border-radius:5px;object-fit:cover;justify-self:center}@media screen and (min-width: 576px){.main-pic{grid-area:main_pic}}.arrow-right{width:8vw;position:relative;z-index:1;cursor:pointer;-webkit-user-select:none;-ms-user-select:none;user-select:none;grid-row-start:3;grid-column-start:4;align-self:center;justify-self:center}@media screen and (min-width: 576px){.arrow-right{grid-area:arrow_right;justify-self:start}}@media screen and (min-width: 768px){.arrow-right{width:5vw;max-width:70px}}.arrow-left{width:8vw;height:auto;position:relative;z-index:1;cursor:pointer;-webkit-user-select:none;-ms-user-select:none;user-select:none;grid-row-start:3;grid-column-start:1;align-self:center;justify-self:center}@media screen and (min-width: 576px){.arrow-left{grid-area:arrow_left;justify-self:end}}@media screen and (min-width: 768px){.arrow-left{width:5vw;max-width:70px}}.btn-close{width:10vw;z-index:1;grid-row-start:1;grid-column-start:4;position:relative;align-self:center;justify-self:center;cursor:pointer}@media screen and (min-width: 576px){.btn-close{grid-area:btn_close;justify-self:start}}@media screen and (min-width: 768px){.btn-close{width:5vw;max-width:50px}}

.about{padding-top:70px}.about p{padding:0 10px}

.container-certificate{display:flex;justify-content:center;grid-column-gap:50px;-webkit-column-gap:50px;column-gap:50px;flex-direction:column}@media screen and (min-width: 992px){.container-certificate{flex-direction:row}}.certificate-card{width:90%;max-width:300px;box-shadow:0 3px 5px rgba(0,0,0,0.288);margin:30px auto;padding:5px;border-radius:5px;position:relative}.certificate-card h4{color:#cc4cd5}.certificate-card p{font-size:0.8em}.medal{position:absolute;fill:#cc4cd5;width:50px;height:auto;text-align:left;text-align:initial;bottom:-15px;right:0}

.schedule{padding-top:70px}.table-schedule{margin:0 auto;text-align:left;text-align:initial}.table-schedule td{padding:10px}

#contact{padding-top:70px;padding-bottom:70px}.contact{overflow:auto}@media screen and (min-width: 992px){.contact{display:flex;flex-direction:row;grid-column-gap:50px;-webkit-column-gap:50px;column-gap:50px;justify-content:center}}.contact>div{margin-bottom:50px}.contact>div h3{padding-bottom:10px}

.footer{text-align:center;background-color:#6a0171;padding:10px;color:white}

