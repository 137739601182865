@import "./variables";
@import "./mixins";


.btn {
  //   grid-row: 3 / 4;
  //   align-self: center;
  text-decoration: none;
  background-color: $mainColor;
  color: $btnText;
  padding: 15px 20px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.288);
  font-size: 1em;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.2s ease-in-out;
   &:hover {
    box-shadow: none;
    transform: scale(1.1);
  }
}

.hidden {
  display:none;
}

.wrapper {
  margin: 0 auto;
  text-align: center;
  @include breakpoint(desktop){
    width: 800px;
  }
  @include breakpoint(largescreen){
    width: 1100px;
  }
}