@import "../../../mixins";
@import "../../../variables";


.nav {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 100vh;
  width: 100%;
  top: 0px;
  right: 0;
  transform: translatex(100%);
  transition: 0.4s ease-in-out;
  z-index: 20;
  // padding: 30px;

  @include breakpoint(desktop){
  // position: absol;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // background-color: white;
  height: 80px;
  // width: 100%;
  top: 0px;
  right: 0;
  transform: initial;
  // transition: 0.4s ease-in-out;
  z-index: 20;
  padding: 0;
  background-color: rgb(235, 235, 235);

  }

}
//nav
.nav__item {
  margin-top: 2.5em;
  margin-bottom: 2.5em;
  opacity: 1;
  text-transform: uppercase;
  list-style: none;
  text-align: center;
  
  @include breakpoint(desktop){
    display: inline;
    padding: 0px 10px;
    margin-top: 0;
    margin-bottom: 0;
    
    }
  
}
.nav__item a {
  font-size: calc(1em + 2vw);
  text-decoration: none;
  color: black;

  @include breakpoint(desktop){
    
    display: inline;
    
    font-size: 1em;
    // padding: 0px 10px;
    transition: 0.1s ease-in-out;
    &:hover {
      // text-decoration: underline;
      border-bottom: 3px solid $mainColor;
      
    }


    }

}

.nav-active {
  transform: translatex(0%);
  //   animation: menuLinkFade 0.2s;

  
}

.nav-list {
  padding: 0px;

  @include breakpoint(desktop){
    display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 10px;

    
    }
  
}

// @keyframes menuLinkFade {
//   from {
//     opacity: 0;
//     transform: translateX(50px);
//   }
//   to {
//     opacity: 1;
//     transform: translateX(0px);
//   }
// }
.btn-contact {
  @include breakpoint(desktop){
    text-decoration: none;
    background-color: $mainColor;
    color: $btnText;
    padding: 10px 15px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 0px 3px rgba(0, 0, 0, 0.288);
    font-size: 1em;
      // color: white;
    cursor: pointer;

    &:active {
      box-shadow: none;
    }
  }
}

.btn-contact a {
  @include breakpoint(desktop){
    color:white;
    &:hover {
      // text-decoration: underline;
      border-bottom: none;
      
    }
  }
}