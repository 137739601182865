@mixin breakpoint($point) {
  @if $point == phone-landscape {
    @media screen and(min-width: 300px) and (orientation:landscape) {
      @content;
    }
  }  

  @else if $point == tablet-landscape {
    @media screen and(min-width: 576px) and (orientation:landscape) {
      @content;
    }
  } 
  @else if $point == laptop-landscape {
    @media screen and(min-width: 768px) and (orientation:landscape) {
      @content;
    }
  }
  @else if $point == desktop-landscape {
    @media screen and(min-width: 992px) and (orientation:landscape) {
      @content;
    }
  }
  @else if $point == largescreen-landscape {
    @media screen and(min-width: 1200px) and (orientation:landscape) {
      @content;
    }
  }


  @else if $point == tablet {
    @media screen and(min-width: 576px) {
      @content;
    }
  } 
  @else if $point == laptop {
    @media screen and(min-width: 768px) {
      @content;
    }
  } 
  @else if $point == desktop {
    @media screen and(min-width: 992px) {
      @content;
    }
  } 
  @else if $point == largescreen {
    @media screen and(min-width: 1200px) {
      @content;
    }
  }
}