@import "../../variables";
@import "../../mixins";

.intro {
  position: relative;
  display: grid;
  // grid-template-rows: 20vh 10vh 10vh 50vh;
  justify-items: center;
  row-gap: 30px;
  padding: $paddingTop 20px 20px 20px;
  // margin-top: 30px;


  margin-top: 50px;
  margin-bottom: 50px;

  @include breakpoint(desktop){
    margin-top: 100px;
    position: relative;
    display: flex;
    /* grid-row-gap: 30px; */
    column-gap: 50px;
    /* row-gap: 30px; */
    /* margin-top: 30px; */
    padding: 30px 0 0 0;
    justify-content: center;
  }

  @include breakpoint(largescreen) {
    margin-top: 200px;
    justify-content: space-between;
  }

}

.intro-logo {
  width: 100%;
  max-width: 400px;
  grid-row: 1 / 2;
  align-self: center;
  object-fit: cover;
}

.intro-text {
  grid-row: 2 / 3;
  align-self: center;

  font-size: 1.3em;
}
.intro-button {
  grid-row: 3 / 4;
  align-self: center;

  background-color: $mainColor;
  color: white;
  padding: 15px 20px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.288);
  font-size: 1em;

  cursor: pointer;
  &:active {
    box-shadow: none;
  }
}

.intro-button-link {
  z-index: 5;
}

.intro-bg {
  width: 100%;
  // grid-row: 4 / 5;
  // align-self: center;
  max-width: 400px;
  margin-top: 50px;
  margin-left: 10px;
  @include breakpoint(largescreen){
    max-width: 500px;
  }
}

.intro-img {
  width: 100%;
}

.container-intro{
  display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
  @include breakpoint(desktop){
    
    /* flex-wrap: nowrap; */
    align-items: flex-start;
    max-width: 400px;
    text-align: left;
  }
  
}